<!-- 运单分享 -->
<template>
  <div class="share-page">
    <el-form
      :model="shareForm"
      :rules="rules"
      ref="rulesForm"
      label-width="65px"
    >
      <el-form-item label="有效期" prop="validityDay">
        <el-radio-group v-model="shareForm.validityDay">
          <el-radio :label="item" v-for="item in validitys" :key="item"
            >{{ item }}天</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="分享码" prop="pwdMethod">
        <el-radio-group v-model="shareForm.pwdMethod">
          <div class="pwd-method">
            <el-radio :label="0">系统随机生成分享码</el-radio>
            <el-radio :label="1">手动设置</el-radio>
            <el-form-item prop="pwd" v-if="shareForm.pwdMethod == 1">
              <el-input
                placeholder="输入4位分享码"
                v-model="shareForm.pwd"
                class="input-pwd"
                size="small"
                maxlength="4"
                clearable
              >
              </el-input
            ></el-form-item>
          </div>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <div>
          <el-checkbox v-model="shareForm.autoPwd"
            >分享链接自动填充分享码</el-checkbox
          >
          <el-tooltip effect="dark" placement="right">
            <div slot="content">
              开启后分享链接会带上分享码，<br />用户点击链接可自动填充分享码
            </div>
            <span><i class="el-icon-warning"></i></span>
          </el-tooltip>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="buts">
          <el-button type="primary" size="small" @click="submitForm"
            >创建链接</el-button
          >
        </div>
      </el-form-item>
      <div style="font-size: 12px; text-align: center; color: rgb(144 144 144)">
        <i class="el-icon-warning-outline"></i> 如分享错误取消分享，请与管理员联系！
      </div>
    </el-form>
    <shareFinish :share-data="shareData" v-if="shareFinish"></shareFinish>
  </div>
</template>

<script>
import shareFinish from '@/components/waybill-share-finish.vue'
import { GetToken } from 'utils/auth'
import WaybillShare from 'api/waybill-share'
export default {
  components: { shareFinish },
  name: '',
  data() {
    // 验证分享码
    let verifyPwd = (rule, value, callback) => {
      try {
        if (this.shareForm.pwdMethod == 1) {
          if (value) {
            const regex = /^[a-zA-Z0-9]+$/
            if (!regex.test(value)) {
              callback(new Error('仅可输入4位数字和字母'))
            }
            if (value.length != 4) {
              callback(new Error('输入4位分享码'))
            }
          } else {
            callback(new Error('输入4位分享码'))
          }
        }
        callback()
      } catch (error) {}
    }
    return {
      validitys: [1, 7, 15, 21],
      shareFinish: false,
      shareForm: {
        validityDay: 1, // 有效期
        pwdMethod: 0, // 分享码方式
        pwd: '', // 查看码
        autoPwd: true, // 分享是否自带查看码
        agencyCode: '', // 代理商（分享人）
        shareWaybill: '' // 分享运单
      },
      shareData: {},
      rules: {
        validityDay: [
          { required: true, message: '请选择有效期', trigger: 'change' }
        ],
        pwdMethod: [
          { required: true, message: '请选择分享码', trigger: 'change' }
        ],
        pwd: [{ validator: verifyPwd, required: true, trigger: 'blur' }]
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    console.log(this.$route.query.agency, 'agency')
    console.log(this.$route.query.key, 'key')
    this.shareForm.agencyCode = this.$route.query.agency
    this.shareForm.shareWaybill = this.$route.query.key
  },
  mounted() {},
  destroyed() {},
  methods: {
    submitForm() {
      this.$refs.rulesForm.validate((valid) => {
        if (valid) {
          WaybillShare.createShareWaybill({
            token: GetToken(),
            ...this.shareForm,
            autoPwd: this.shareForm.autoPwd ? 1 : 0
          }).then((res) => {
            if (res.success) {
              this.shareData = res.record
              this.shareFinish = true
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.share-page {
  padding: 20px;
  background: #fff;
  height: calc(100vh - 40px);
  .pwd-method {
    display: flex;
    align-items: center;
    .input-pwd {
      width: 120px;
    }
  }
  .el-icon-warning {
    margin-left: 5px;
    color: #696969;
  }
  .buts {
    text-align: center;
  }
}
</style>
